import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"teaser-role-talent-individual-form-container pt-6 pt-md-0"},[_c(VForm,{ref:"personalInformationForm",staticClass:"personal-information-form__wrapper",model:{value:(_vm.personalInformationFormValid),callback:function ($$v) {_vm.personalInformationFormValid=$$v},expression:"personalInformationFormValid"}},[_vm._l((_vm.personalInfoFormFields),function(item,index){return [(item.key == 'gender')?_c('div',{key:("gender-" + index),staticClass:"teaser__form-field",class:("form-" + (item.key))},[_c('div',[_vm._v(_vm._s(_vm.$t(("label." + (item.label)))))]),_c('div',{staticClass:"d-flex flex-wrap",attrs:{"id":"personal-info-gender-radios"}},[_vm._l((item.options),function(radio,radioIndex){return [_c('AppFormField',{key:radioIndex,staticClass:"text-subtitle-1 text-xl-h6",attrs:{"type":"radio","label":radio.text,"id":("personalGender-" + (radio.value)),"radioValue":radio.value},on:{"input":function($event){return _vm.personalInfoFormFieldInput(item.key)}},model:{value:(_vm.talentIndividualFormObj[item.key]),callback:function ($$v) {_vm.$set(_vm.talentIndividualFormObj, item.key, $$v)},expression:"talentIndividualFormObj[item.key]"}})]})],2)]):(item.field != 'other')?_c('AppFormField',{key:index,staticClass:"teaser__form-field",class:("form-" + (item.key)),attrs:{"dark":"","dense":"","outlined":"","label":_vm.$t(("label." + (item.label))),"height":40,"type":item.formType,"items":item.options,"itemColor":"background","color":item.color,"rules":item.key == 'mobile'
            ? _vm.formValidation.mobileNumberRules(
                _vm.talentIndividualFormObj.mobile,
                _vm.talentIndividualFormObj.mobileDialCode
              )
            : item.rules,"multiple":item.multiple},on:{"input":function($event){return _vm.personalInfoFormFieldInput(item.key)}},scopedSlots:_vm._u([(item.key == 'mobile')?{key:"prepend-inner",fn:function(){return [_c('AppFormField',{attrs:{"outlined":"","dense":"","height":30,"items":_vm.phoneCodes,"type":"select","hideDetails":"","itemColor":"background","color":"surface"},model:{value:(_vm.talentIndividualFormObj.mobileDialCode),callback:function ($$v) {_vm.$set(_vm.talentIndividualFormObj, "mobileDialCode", $$v)},expression:"talentIndividualFormObj.mobileDialCode"}})]},proxy:true}:null],null,true),model:{value:(_vm.talentIndividualFormObj[item.key]),callback:function ($$v) {_vm.$set(_vm.talentIndividualFormObj, item.key, $$v)},expression:"talentIndividualFormObj[item.key]"}}):_vm._e()]}),_vm._l((_vm.personalInfoFormFields),function(item,index){return [(
          item.field == 'other' &&
          _vm.isMatchingParentValue(item.parent, item.showValue)
        )?_c('AppFormField',{key:("other-" + index),staticClass:"teaser__form-field",class:("form-" + (item.key)),attrs:{"dark":"","dense":"","label":_vm.$t(("label." + (item.label))),"height":40,"type":item.formType,"items":item.options,"itemColor":"background","rules":item.rules,"color":"surface"},model:{value:(_vm.talentIndividualFormObj[item.key]),callback:function ($$v) {_vm.$set(_vm.talentIndividualFormObj, item.key, $$v)},expression:"talentIndividualFormObj[item.key]"}}):_vm._e()]})],2),(_vm.isBelow18)?_c('div',{staticClass:"guardian-information-form__container"},[_c('div',{staticClass:"guardian-information-form__title"},[_c('div',{staticClass:"text-capitalize font-weight-medium text-h6 text-lg-h5"},[_vm._v(" "+_vm._s(_vm.$t('label.guardiansInformation'))+" ")]),_c('div',{staticClass:"text-caption text-lg-body-2"},[_vm._v(" "+_vm._s(_vm.$t('message.ifYouAreUnderTheAgeOf18'))+" ")])]),_c(VForm,{ref:"guardianInformationForm",staticClass:"guardian-information-form__wrapper",class:[
        { addOn: _vm.talentIndividualFormObj.guardianRelationship == 'other' }
      ],model:{value:(_vm.guardianInformationFormValid),callback:function ($$v) {_vm.guardianInformationFormValid=$$v},expression:"guardianInformationFormValid"}},[_vm._l((_vm.guardianInfoFormFields),function(item,index){return [(item.key == 'guardianGender')?_c('div',{key:("guardianGender-" + index),staticClass:"teaser__form-field",class:("form-" + (item.key))},[_c('div',[_vm._v(_vm._s(_vm.$t(("label." + (item.label)))))]),_c('div',{staticClass:"d-flex flex-wrap"},[_vm._l((item.options),function(radio,radioIndex){return [_c('AppFormField',{key:radioIndex,staticClass:"text-subtitle-1 text-xl-h6",attrs:{"type":"radio","label":radio.text,"id":("guardianGender-" + (radio.value)),"radioValue":radio.value},on:{"input":function($event){return _vm.guardianInfoFormFieldInput(item.key)}},model:{value:(_vm.talentIndividualFormObj[item.key]),callback:function ($$v) {_vm.$set(_vm.talentIndividualFormObj, item.key, $$v)},expression:"talentIndividualFormObj[item.key]"}})]})],2)]):(item.field != 'other')?_c('AppFormField',{key:index,staticClass:"teaser__form-field",class:("form-" + (item.key)),attrs:{"dark":"","dense":"","outlined":"","label":_vm.$t(("label." + (item.label))),"height":40,"type":item.formType,"items":item.options,"itemColor":"background","color":"surface","rules":item.key == 'guardianMobile'
              ? _vm.formValidation.mobileNumberRules(
                  _vm.talentIndividualFormObj.mobileDialCode
                )
              : item.rules,"multiple":item.multiple},on:{"input":function($event){return _vm.guardianInfoFormFieldInput(item.key)}},scopedSlots:_vm._u([(item.key == 'guardianMobile')?{key:"prepend-inner",fn:function(){return [_c('AppFormField',{attrs:{"outlined":"","dense":"","height":30,"items":_vm.phoneCodes,"type":"select","hideDetails":"","itemColor":"background","color":"surface"},model:{value:(_vm.talentIndividualFormObj.guardianMobileDialCode),callback:function ($$v) {_vm.$set(_vm.talentIndividualFormObj, "guardianMobileDialCode", $$v)},expression:"talentIndividualFormObj.guardianMobileDialCode"}})]},proxy:true}:null],null,true),model:{value:(_vm.talentIndividualFormObj[item.key]),callback:function ($$v) {_vm.$set(_vm.talentIndividualFormObj, item.key, $$v)},expression:"talentIndividualFormObj[item.key]"}}):_vm._e()]}),_vm._l((_vm.guardianInfoFormFields),function(item,index){return [(
            item.field == 'other' &&
            _vm.isMatchingParentValue(item.parent, item.showValue)
          )?_c('AppFormField',{key:("other-" + index),staticClass:"teaser__form-field",class:("form-" + (item.key)),attrs:{"dark":"","dense":"","label":_vm.$t(("label." + (item.label))),"height":40,"type":item.formType,"items":item.options,"itemColor":"background","color":"surface","rules":item.rules},model:{value:(_vm.talentIndividualFormObj[item.key]),callback:function ($$v) {_vm.$set(_vm.talentIndividualFormObj, item.key, $$v)},expression:"talentIndividualFormObj[item.key]"}}):_vm._e()]})],2)],1):_vm._e(),_c(VDivider,{staticClass:"divider"}),_c('div',{staticClass:"d-flex justify-center"},[_c('div',[_vm._l((_vm.checkboxes),function(item,index){return [(
            item.key == 'understoodAcknowledgeNConsent' ? _vm.isBelow18 : true
          )?_c('div',{key:index,staticClass:"mb-6"},[_c('AppCheckBox',{staticClass:"teaser-application__app-check-box",attrs:{"label":_vm.$t(("message." + (item.label)))},model:{value:(_vm.checkboxObj[item.key]),callback:function ($$v) {_vm.$set(_vm.checkboxObj, item.key, $$v)},expression:"checkboxObj[item.key]"}})],1):_vm._e()]})],2)]),_c('div',{staticClass:"personal-information-form__signUp-button-container d-flex justify-center"},[_c('AppButton',{staticClass:"teaser-application__button",attrs:{"disabled":!_vm.personalInformationFormIsValid || !_vm.guardianInformationFormIsValid,"minHeight":"60","minWidth":"300"},on:{"click":_vm.signUpAction}},[_vm._v(_vm._s(_vm.$t('label.signUp')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }