<template>
  <div
    class="teaser-role-talent-seeker-production-house-form-container pt-6 pt-md-0"
  >
    <!-- Personal Information Form Start -->
    <v-form
      v-model="personalInformationFormValid"
      ref="personalInformationForm"
      class="personal-information-form__wrapper"
      :class="[
        {
          addOn: talentSeekerProductionHouseFormObj.description == 10
        }
      ]"
    >
      <!-- Personal Info Form Fields Start -->
      <template v-for="(item, index) in personalInfoFormFields">
        <AppFormField
          v-if="item.field != 'other'"
          :key="index"
          v-model="talentSeekerProductionHouseFormObj[item.key]"
          dark
          dense
          outlined
          :label="$t(`label.${item.label}`)"
          :height="40"
          :type="item.formType"
          :items="item.options"
          itemColor="background"
          :color="item.color"
          :rules="
            item.key == 'mobile'
              ? formValidation.mobileNumberRules(
                  talentSeekerProductionHouseFormObj.mobile,
                  talentSeekerProductionHouseFormObj.mobileDialCode
                )
              : item.rules
          "
          :multiple="item.multiple"
          class="teaser__form-field"
          :class="`form-${item.key}`"
          @input="personalInfoFormFieldInput(item.key)"
        >
          <template v-if="item.key == 'mobile'" v-slot:prepend-inner>
            <AppFormField
              v-model="talentSeekerProductionHouseFormObj.mobileDialCode"
              outlined
              dense
              :height="30"
              :items="phoneCodes"
              type="select"
              hideDetails
              itemColor="background"
              color="surface"
              class="prepend-inner foo"
            >
            </AppFormField>
          </template>
        </AppFormField>
      </template>
      <!-- Personal Info Form Fields End -->

      <!-- Other Fields Start -->
      <template v-for="(item, index) in personalInfoFormFields">
        <AppFormField
          v-if="
            item.field == 'other' &&
            talentSeekerProductionHouseFormObj[item.parent] == item.showValue
          "
          :key="`other-${index}`"
          v-model="talentSeekerProductionHouseFormObj[item.key]"
          dark
          dense
          :label="$t(`label.${item.label}`)"
          :height="40"
          :type="item.formType"
          :items="item.options"
          itemColor="background"
          :rules="item.rules"
          color="surface"
          class="teaser__form-field"
          :class="`form-${item.key}`"
        >
        </AppFormField>
      </template>
      <!-- Other Fields End -->
    </v-form>
    <!-- Personal Information Form end -->

    <v-divider class="divider"></v-divider>

    <div class="d-flex justify-center">
      <div>
        <template v-for="(item, index) in checkboxes">
          <div :key="index" class="mb-6">
            <AppCheckBox
              v-model="checkboxObj[item.key]"
              :label="$t(`message.${item.label}`)"
              class="teaser-application__app-check-box"
            ></AppCheckBox>
          </div>
        </template>
      </div>
    </div>
    <div
      class="personal-information-form__signUp-button-container d-flex justify-center"
    >
      <AppButton
        :disabled="!personalInformationFormIsValid"
        minHeight="60"
        minWidth="300"
        class="teaser-application__button"
        @click="signUpAction"
        >{{ $t('label.signUp') }}</AppButton
      >
    </div>
  </div>
</template>

<script>
  import common from '@/mixin/common.mixin';
  import AppCheckBox from '@/components/base/AppCheckBox';
  import { uiHelper, formValidationHelper, i18nHelper } from '@/utils';
  import { listService, i18nService } from '@/services';
  import { LOCAL, SHARED } from '@/constants';
  import { REGISTER_TYPE } from '@/constants/enums';
  import {
    AUTH_CHECK_AVAILABILITY_TEASER,
    AUTH_INITIAL_CHECK_AVAILABILITY_TEASER_STATE
  } from '@/store/auth.module';

  export default {
    name: 'TeaserRoleTalentSeekerProductionHouseForm',
    components: {
      AppCheckBox
    },
    mixins: [common],
    props: {
      value: {
        type: Object,
        default: () => ({})
      },
      role: {
        type: String,
        default: ''
      },
      otpType: {
        type: String,
        default: ''
      },
      isResetMobile: {
        type: Boolean,
        default: false
      },
      isSignUpError: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      personalInformationFormValid: false,
      talentSeekerProductionHouseFormObj: {
        name: null,
        firstName: null,
        lastName: null,
        description: null,
        descriptionOther: null,
        countryId: null,
        mobileDialCode: SHARED.DEFAULT_PHONE_CODE,
        mobile: null,
        email: null,
        getotpby: null,
        auth_method: null,
        registerType: REGISTER_TYPE.PRE_USER
      },
      checkboxObj: {
        agreeTermsOfUseNPrivacyPolicy: false
      },
      personalInfoFormFields: [],
      checkboxes: [
        {
          key: 'agreeTermsOfUseNPrivacyPolicy',
          label: 'messageAgreeTermsOfUseNPrivacyPolicy'
        }
      ],
      currentLanguage: i18nService.getActiveLanguage(),
      formValidation: formValidationHelper
    }),
    computed: {
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      },
      phoneCodes() {
        return listService.getPhoneCodeList(this.constantsData.country);
      },
      personalInformationFormIsValid() {
        if (
          (this.talentSeekerProductionHouseFormObj.mobile ||
            this.talentSeekerProductionHouseFormObj.email) &&
          this.checkboxObj.agreeTermsOfUseNPrivacyPolicy
        ) {
          return this.personalInformationFormValid;
        } else {
          return false;
        }
      },
      checkAvailabilityTeaserComplete() {
        return this.$store.state.auth.checkAvailabilityTeaser.complete;
      }
    },
    watch: {
      checkAvailabilityTeaserComplete() {
        let response = this.$store.state.auth.checkAvailabilityTeaser;

        if (response.complete) {
          this.checkAvailabilityTeaserCompleteAction(response);
        }
      }
    },
    mounted() {
      this.initialFormFields();
      this.insertAgreeMessageHyperlink();
      this.initialTalentSeekerProductionHouseFormObj();
    },
    methods: {
      checkAvailabilityTeaserCompleteAction(response) {
        if (response.code == 200) {
          this.$emit('input', this.talentSeekerProductionHouseFormObj);
          this.$emit('sign-up');
        } else {
          let type = 'error';
          let title = i18nHelper.getMessage('label.userAvailability');
          let description = response.message;
          let buttons = [
            {
              color: 'title',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ];
          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.initialCheckAvailabilityTeaserState();
      },
      personalInfoFormFieldInput(key) {
        if (key == 'description') {
          if (this.talentSeekerProductionHouseFormObj[key] != 9) {
            this.talentSeekerProductionHouseFormObj.descriptionOther = null;
          }
        }
      },
      signUpAction() {
        let obj = this.talentSeekerProductionHouseFormObj;
        let data = {
          name: obj.name,
          mobileDialCode: obj.mobileDialCode,
          mobile: obj.mobile,
          email: obj.email,
          auth_method: obj.getotpby,
          role: this.role
        };

        if (this.personalInformationFormIsValid) {
          this.checkAvailabilityTeaser(data);
        }
      },
      insertAgreeMessageHyperlink() {
        let agreeMessageDiv = document.getElementById('hyperlink-tou-pp');

        agreeMessageDiv.setAttribute('href', '/terms-of-use');
        agreeMessageDiv.setAttribute('target', '_blank');
        agreeMessageDiv.classList.add('secondary--text');
      },
      initialFormFields() {
        this.personalInfoFormFields = [
          {
            key: 'name',
            label: 'companyName',
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.companyName'),
              2,
              50
            )
          },
          {
            key: 'description',
            label: 'weAreAAn',
            formType: 'select',
            options: listService.getDynamicList(
              this.constantsData.productionHouseDescription
            ),
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.weAreAAn')
            )
          },
          {
            key: 'descriptionOther',
            label: 'pleaseSpecific',
            field: 'other',
            parent: 'description',
            showValue: 10,
            rules: formValidationHelper.requiredRules()
          },

          {
            key: 'firstName',
            label: 'firstName',
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.firstName'),
              2,
              50
            )
          },
          {
            key: 'lastName',
            label: 'lastName',
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.lastName'),
              2,
              50
            )
          },
          {
            key: 'countryId',
            label: 'countryOfResidence',
            formType: 'select',
            options: listService.getCountryList(
              this.constantsData.country,
              this.currentLanguage
            ),
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.countryOfResidence')
            ),
            multiple: true
          },
          {
            key: 'mobile',
            label: 'mobileNo'
          },
          {
            key: 'email',
            label: 'email',
            rules: formValidationHelper.emailRules()
          },
          {
            key: 'getotpby',
            label: 'getotpby',
            formType: 'select',
            options: [
              { text: 'Email', value: 'email' },
              { text: 'Mobile No', value: 'mobile' }
            ],
            rules: formValidationHelper.requiredRules()
          }
        ];
      },
      initialTalentSeekerProductionHouseFormObj() {
        if (this.isResetMobile || this.isSignUpError) {
          this.talentSeekerProductionHouseFormObj = this.value;
        }
      },
      checkAvailabilityTeaser(data) {
        this.$store.dispatch(AUTH_CHECK_AVAILABILITY_TEASER, { data });
      },
      initialCheckAvailabilityTeaserState() {
        this.$store.dispatch(AUTH_INITIAL_CHECK_AVAILABILITY_TEASER_STATE);
      }
    }
  };
</script>

<style lang="scss">
  .teaser-role-talent-seeker-production-house-form-container {
    .divider {
      margin: 100px 0 50px 0;
    }
    .personal-information-form__wrapper {
      width: 100%;
      display: grid;
      row-gap: 24px;
      column-gap: 30px;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(4, 1fr);

      &.addOn {
        grid-template-rows: repeat(5, 1fr) !important;
        .teaser__form-field {
          &.form-firstName {
            grid-column: 1/7;
            grid-row: 3;
          }
          &.form-lastName {
            grid-column: 7/13;
            grid-row: 3;
          }
          &.form-countryId {
            grid-column: 1/7;
            grid-row: 4;
          }
          &.form-mobile {
            grid-column: 7/13;
            grid-row: 4;
          }
          &.form-email {
            grid-column: 1/7;
            grid-row: 5;
          }
          &.form-getotpby {
            grid-column: 7/13;
            grid-row: 5;
          }
        }
      }

      .teaser__form-field {
        &.form-name {
          grid-column: 1/7;
          grid-row: 1;
        }
        &.form-description {
          grid-column: 7/13;
          grid-row: 1;
        }
        &.form-descriptionOther {
          grid-column: 7/13;
          grid-row: 2;
        }
        &.form-firstName {
          grid-column: 1/7;
          grid-row: 2;
        }
        &.form-lastName {
          grid-column: 7/13;
          grid-row: 2;
        }
        &.form-countryId {
          grid-column: 1/7;
          grid-row: 3;
        }
        &.form-mobile {
          grid-column: 7/13;
          grid-row: 3;
        }
        &.form-email {
          grid-column: 1/7;
          grid-row: 4;
        }
        &.form-getotpby {
          grid-column: 7/13;
          grid-row: 4;
        }
      }
    }
  }

  @media (max-width: 959px) {
    .teaser-role-talent-seeker-production-house-form-container {
      .divider {
        margin: 80px 0 50px 0;
      }

      .personal-information-form__wrapper {
        row-gap: 12px;
        column-gap: 0;

        .teaser__form-field {
          &.form-name {
            grid-column: 1/13;
            grid-row: 1;
          }
          &.form-description {
            grid-column: 1/13;
            grid-row: 2;
          }
          &.form-firstName {
            grid-column: 1/13;
            grid-row: 3;
          }
          &.form-lastName {
            grid-column: 1/13;
            grid-row: 4;
          }
          &.form-countryId {
            grid-column: 1/13;
            grid-row: 5;
          }
          &.form-mobile {
            grid-column: 1/13;
            grid-row: 6;
          }
          &.form-email {
            grid-column: 1/13;
            grid-row: 7;
          }
          &.form-getotpby {
            grid-column: 1/13;
            grid-row: 8;
          }
        }

        &.addOn {
          .teaser__form-field {
            &.form-name {
              grid-column: 1/13;
              grid-row: 1;
            }
            &.form-description {
              grid-column: 1/13;
              grid-row: 2;
            }
            &.form-descriptionOther {
              grid-column: 1/13;
              grid-row: 3;
            }
            &.form-firstName {
              grid-column: 1/13;
              grid-row: 4;
            }
            &.form-lastName {
              grid-column: 1/13;
              grid-row: 5;
            }
            &.form-countryId {
              grid-column: 1/13;
              grid-row: 6;
            }
            &.form-mobile {
              grid-column: 1/13;
              grid-row: 7;
            }
            &.form-email {
              grid-column: 1/13;
              grid-row: 8;
            }
          }
        }
      }
    }
  }
</style>
