<template>
  <div class="app-check-box">
    <div class="app-check-box__icon-container">
      <img
        :src="`/assets/components/AppCheckBox/checkbox-${
          check ? 'check' : 'blank'
        }.svg`"
        alt="check"
        class="app-check-box__icon"
        @click="onClick"
      />
    </div>
    <div
      v-if="!customisedLabel"
      v-html="label"
      class="app-check-box__label"
    ></div>
    <div v-else class="app-check-box__label">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppCheckBox',
    props: {
      value: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      customisedLabel: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      check: false
    }),
    mounted() {
      this.initAppCheckBox();
    },
    methods: {
      onClick() {
        this.check = !this.check;

        this.$emit('input', this.check);
      },
      initAppCheckBox() {
        this.check = this.value;
      }
    }
  };
</script>

<style lang="scss">
  .app-check-box {
    display: flex;
    align-items: center;
    .app-check-box__icon-container {
      display: flex;
      padding-right: 24px;
      .app-check-box__icon {
        cursor: pointer;
        transition: all 3s ease-in-out;
        transition-duration: 0.5s;
      }
    }
    .app-check-box__label {
      color: #ffffff;
    }
  }
</style>
