<template>
  <div class="teaser-application">
    <v-fade-transition>
      <TeaserPageLayout
        v-if="!role"
        :title="$t('label.whoAmI')"
        :messageSlot="$vuetify.breakpoint.mdAndUp"
      >
        <TeaserRoleSelection v-model="role"></TeaserRoleSelection>
      </TeaserPageLayout>
    </v-fade-transition>

    <v-fade-transition>
      <template v-if="!requestOTP">
        <template v-for="(item, index) in forms">
          <TeaserPageLayout
            :key="index"
            v-if="role == item.role"
            :title="$t(`label.${item.title}`)"
            :messageSlot="$vuetify.breakpoint.mdAndUp"
            class="transition"
          >
            <component
              :is="item.component"
              v-model="formObj[item.key]"
              :role="role"
              :otpType="otpType"
              :isResetMobile="isResetMobile"
              :isSignUpError="isSignUpError"
              @sign-up="signUpAction(role)"
            ></component>
          </TeaserPageLayout>
        </template>
      </template>
    </v-fade-transition>

    <v-fade-transition>
      <TeaserPageLayout
        v-if="requestOTP"
        :title="$t('label.enterOTP')"
        :messageSlot="true"
        class="teaser-application__teaser-otp-page-layout"
      >
        <template v-slot:message>
          <div v-if="authMethod == 'mobile'">
            <div>{{ $t('message.aSixDigitCodeHasBeenSentViaSMSTo') }}</div>
            <div>
              {{ phoneNumber }}
            </div>
          </div>
          <div v-if="authMethod == 'email'">
            <div>{{ $t('message.aSixDigitCodeHasBeenSentViaEmailTo') }}</div>
            <div>
              {{ dEmail }}
            </div>
          </div>
        </template>

        <TeaserOTP
          :value="value"
          @change-mobile-number="changeMobileNumberAction"
          @verify-otp-success="verifyOTPSuccessAction"
        ></TeaserOTP>
      </TeaserPageLayout>
    </v-fade-transition>
  </div>
</template>

<script>
  import common from '@/mixin/common.mixin';
  import TeaserPageLayout from '@/components/teaser/TeaserPageLayout';
  import TeaserRoleSelection from '@/components/teaser/TeaserRoleSelection';
  import TeaserRoleTalentIndividualForm from '@/components/teaser/TeaserRoleTalentIndividualForm';
  import TeaserRoleTalentAgencyForm from '@/components/teaser/TeaserRoleTalentAgencyForm';
  import TeaserRoleTalentSeekerFreelancerForm from '@/components/teaser/TeaserRoleTalentSeekerFreelancerForm';
  import TeaserRoleTalentSeekerProductionHouseForm from '@/components/teaser/TeaserRoleTalentSeekerProductionHouseForm';
  import TeaserOTP from '@/components/teaser/TeaserOTP';
  import { i18nHelper, uiHelper } from '@/utils';
  import { ROUTE_NAME, SESSION } from '@/constants';
  import { ROLE_TYPE, OTP_TYPE } from '@/constants/enums';
  import {
    AUTH_REGISTER_TALENT,
    AUTH_REGISTER_AGENCY,
    AUTH_REGISTER_FREELANCER,
    AUTH_REGISTER_PRODUCTION_HOUSE,
    AUTH_INITIAL_REGISTER_TALENT_STATE,
    AUTH_INITIAL_REGISTER_AGENCY_STATE,
    AUTH_INITIAL_REGISTER_FREELANCER_STATE,
    AUTH_INITIAL_REGISTER_PRODUCTION_HOUSE_STATE
  } from '@/store/auth.module';

  export default {
    name: 'TeaserApplication',
    components: {
      TeaserPageLayout,
      TeaserRoleSelection,
      TeaserRoleTalentIndividualForm,
      TeaserRoleTalentAgencyForm,
      TeaserRoleTalentSeekerFreelancerForm,
      TeaserRoleTalentSeekerProductionHouseForm,
      TeaserOTP
    },
    mixins: [common],
    data: () => ({
      role: null,
      otpType: OTP_TYPE.PRE_USER,
      requestOTP: false,
      formObj: {
        individual: null,
        agency: null,
        freelancer: null,
        productionHouse: null
      },
      value: null,
      isResetMobile: false,
      registerSuccessfully: false,
      isSignUpError: false,
      authMethod: null,
      email: null,
      forms: [
        {
          key: 'individual',
          role: ROLE_TYPE.TALENT,
          title: 'moreAboutMe',
          component: 'TeaserRoleTalentIndividualForm'
        },
        {
          key: 'agency',
          role: ROLE_TYPE.AGENCY,
          title: 'moreAboutUs',
          component: 'TeaserRoleTalentAgencyForm'
        },
        {
          key: 'freelancer',
          role: ROLE_TYPE.FREELANCER,
          title: 'moreAboutMe',
          component: 'TeaserRoleTalentSeekerFreelancerForm'
        },
        {
          key: 'productionHouse',
          role: ROLE_TYPE.PRODUCTION_HOUSE,
          title: 'moreAboutUs',
          component: 'TeaserRoleTalentSeekerProductionHouseForm'
        }
      ]
    }),
    computed: {
      phoneNumber() {
        let obj = this.getRoleObject(this.role);
        let trimCount = {
          '+65': 4,
          '+60': 5
        };
        let hiddenCount = {
          '+65': 'XXXX',
          '+60': 'XXXXX'
        };

        if (obj) {
          return `${obj.mobileDialCode}-${
            hiddenCount[obj.mobileDialCode]
          } ${obj.mobile.substr(trimCount[obj.mobileDialCode])}`;
        } else {
          return '+65-XXXXXXX';
        }
      },
      dEmail() {
        let obj = this.getRoleObject(this.role);

        if (obj) {
          var email = obj.email,
            xtext = '';
          const splitemail = email.split('@');

          for (var i = 0; i < splitemail[0].length - 1; i++) {
            xtext += 'X';
          }

          return `${obj.email.substr(0, 1)}${xtext}@${splitemail[1]}`;
        } else {
          return 'TaXXX@email.com';
        }
      },
      registerTalentComplete() {
        return this.$store.state.auth.registerTalent.complete;
      },
      registerAgencyComplete() {
        return this.$store.state.auth.registerAgency.complete;
      },
      registerFreelancerComplete() {
        return this.$store.state.auth.registerFreelancer.complete;
      },
      registerProductionHouseComplete() {
        return this.$store.state.auth.registerProductionHouse.complete;
      }
    },
    watch: {
      requestOTP() {
        this.$nextTick(function () {
          this.toTopView();
        });
      },
      registerTalentComplete() {
        let response = this.$store.state.auth.registerTalent;

        if (response.complete) {
          this.registerTalentCompleteAction(response);
        }
      },
      registerAgencyComplete() {
        let response = this.$store.state.auth.registerAgency;

        if (response.complete) {
          this.registerAgencyCompleteAction(response);
        }
      },
      registerFreelancerComplete() {
        let response = this.$store.state.auth.registerFreelancer;

        if (response.complete) {
          this.registerFreelancerCompleteAction(response);
        }
      },
      registerProductionHouseComplete() {
        let response = this.$store.state.auth.registerProductionHouse;

        if (response.complete) {
          this.registerProductionHouseCompleteAction(response);
        }
      }
    },
    methods: {
      registerProductionHouseCompleteAction(response) {
        let data = {
          registerName: this.getRoleObject(this.role).firstName
        };
        if (response.code == 200) {
          this.setTeaserInfo(data);
          this.$router.push({ name: ROUTE_NAME.TEASER_REGISTER_SUCCESS });
        } else {
          let type = 'error';
          let title = i18nHelper.getMessage(
            'label.registerProductionOrCorporateOrAgency'
          );
          let description = response.message;
          let buttons = [
            {
              color: 'title',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
                this.backToForm();
              }
            }
          ];
          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.initialRegisterProductionHouseState();
      },
      registerFreelancerCompleteAction(response) {
        let data = {
          registerName: this.getRoleObject(this.role).firstName
        };
        if (response.code == 200) {
          this.setTeaserInfo(data);
          this.$router.push({ name: ROUTE_NAME.TEASER_REGISTER_SUCCESS });
        } else {
          let type = 'error';
          let title = i18nHelper.getMessage('label.registerFreelancer');
          let description = response.message;
          let buttons = [
            {
              color: 'title',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
                this.backToForm();
              }
            }
          ];
          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.initialRegisterFreelancer();
      },
      registerAgencyCompleteAction(response) {
        let data = {
          registerName: this.getRoleObject(this.role).firstName
        };
        if (response.code == 200) {
          this.setTeaserInfo(data);
          this.$router.push({ name: ROUTE_NAME.TEASER_REGISTER_SUCCESS });
        } else {
          let type = 'error';
          let title = i18nHelper.getMessage('label.registerTalentAgency');
          let description = response.message;
          let buttons = [
            {
              color: 'title',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
                this.backToForm();
              }
            }
          ];
          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.initialRegisterAgency();
      },
      registerTalentCompleteAction(response) {
        let data = {
          registerName: this.getRoleObject(this.role).firstName,
          role: this.role
        };
        if (response.code == 200) {
          this.setTeaserInfo(data);
          this.$router.push({ name: ROUTE_NAME.TEASER_REGISTER_SUCCESS });
        } else {
          let type = 'error';
          let title = i18nHelper.getMessage('label.registerIndividualTalent');
          let description = response.message;
          let buttons = [
            {
              color: 'title',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
                this.backToForm();
              }
            }
          ];
          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.initialRegisterTalent();
      },
      backToForm() {
        this.requestOTP = false;
        this.value = null;
        this.isSignUpError = true;
      },
      verifyOTPSuccessAction() {
        let role = this.role;
        let obj = this.getRoleObject(role);

        switch (role) {
          case ROLE_TYPE.TALENT:
            this.registerTalent(obj);
            break;
          case ROLE_TYPE.AGENCY:
            this.registerAgency(obj);
            break;
          case ROLE_TYPE.FREELANCER:
            this.registerFreelancer(obj);
            break;
          case ROLE_TYPE.PRODUCTION_HOUSE:
            this.registerProductionHouse(obj);
            break;
        }
      },
      signUpAction(role) {
        let obj = this.getRoleObject(role);
        let data = {
          mobileDialCode: obj.mobileDialCode,
          mobile: obj.mobile,
          email: obj.email,
          auth_method: obj.getotpby,
          otpType: this.otpType
        };

        if (obj) {
          this.authMethod = obj.getotpby;
          this.requestOTP = true;
          this.value = data;
        }
      },
      getRoleObject(roleType) {
        let objList = {
          [ROLE_TYPE.TALENT]: 'individual',
          [ROLE_TYPE.AGENCY]: 'agency',
          [ROLE_TYPE.FREELANCER]: 'freelancer',
          [ROLE_TYPE.PRODUCTION_HOUSE]: 'productionHouse'
        };

        return this.formObj[objList[roleType]];
      },
      changeMobileNumberAction() {
        this.requestOTP = false;
        this.value = null;
        this.isResetMobile = true;
      },
      toTopView() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      setTeaserInfo(data) {
        uiHelper.setSessionStorage(SESSION.TEASER, JSON.stringify(data));
      },
      registerTalent(data) {
        this.$store.dispatch(AUTH_REGISTER_TALENT, { data });
      },
      initialRegisterTalent() {
        this.$store.dispatch(AUTH_INITIAL_REGISTER_TALENT_STATE);
      },
      registerAgency(data) {
        this.$store.dispatch(AUTH_REGISTER_AGENCY, { data });
      },
      initialRegisterAgency() {
        this.$store.dispatch(AUTH_INITIAL_REGISTER_AGENCY_STATE);
      },
      registerFreelancer(data) {
        this.$store.dispatch(AUTH_REGISTER_FREELANCER, { data });
      },
      initialRegisterFreelancer() {
        this.$store.dispatch(AUTH_INITIAL_REGISTER_FREELANCER_STATE);
      },
      registerProductionHouse(data) {
        this.$store.dispatch(AUTH_REGISTER_PRODUCTION_HOUSE, { data });
      },
      initialRegisterProductionHouseState() {
        this.$store.dispatch(AUTH_INITIAL_REGISTER_PRODUCTION_HOUSE_STATE);
      }
    }
  };
</script>

<style lang="scss">
  .teaser-application {
    .teaser__form-field {
      .theme--dark.v-text-field--outlined:not(.v-input--is-focused):not(
          .v-input--has-state
        )
        > .v-input__control
        > .v-input__slot
        fieldset {
        color: var(--v-disabled-base) !important;
      }
      .v-input__prepend-inner {
        margin-top: 0 !important;
        max-width: 18%;
      }
    }
    .teaser-application__button-container {
      min-height: 35vh;
    }

    .teaser-application__button {
      min-height: 60px !important;
      min-width: 300px !important;
      font-size: 1.875rem;
    }

    .teaser-application__teaser-otp-page-layout {
      transition-delay: 1s !important;
    }

    .transition {
      transition-delay: 0.5s !important;
      transition-duration: 0.3s !important;
    }
    .personal-information-form__signUp-button-container {
      padding: 50px 0 200px 0;
    }
  }

  .teaser-application
    .teaser__form-field
    .v-input__prepend-inner
    .app-form-field
    .theme--dark.v-text-field--outlined {
    &:not(.v-input--is-focused):not(.v-input--has-state)
      > .v-input__control
      > .v-input__slot
      fieldset {
      color: transparent !important;
    }
    > .v-input__control > .v-input__slot fieldset {
      color: transparent !important;
    }
  }

  @media (max-width: 1903px) {
    .teaser-application {
      .teaser-application__button {
        min-height: 55px !important;
        min-width: 250px !important;
        font-size: 1.85rem;
      }
      .personal-information-form__signUp-button-container {
        padding: 48px 0 180px 0;
      }
    }
  }
  @media (max-width: 1599px) {
    .teaser-application {
      .teaser-application__button {
        min-height: 50px !important;
        min-width: 200px !important;
        font-size: 1.75rem;
      }

      .personal-information-form__signUp-button-container {
        padding: 46px 0 160px 0;
      }

      .teaser__form-field {
        .v-input__prepend-inner {
          max-width: 19%;
        }
      }
    }
  }
  @media (max-width: 1535px) {
    .teaser-application {
      .teaser-application__button {
        min-height: 45px !important;
        min-width: 150px !important;
        font-size: 1.6rem;
      }

      .personal-information-form__signUp-button-container {
        padding: 44px 0 140px 0;
      }

      .teaser__form-field {
        .v-input__prepend-inner {
          max-width: 20%;
        }
      }
    }
  }
  @media (max-width: 1439px) {
    .teaser-application {
      .teaser-application__button {
        min-height: 40px !important;
        min-width: 100px !important;
        font-size: 1.575rem;
      }

      .personal-information-form__signUp-button-container {
        padding: 42px 0 120px 0;
      }

      .teaser__form-field {
        .v-input__prepend-inner {
          max-width: 21%;
        }
      }
    }
  }
  @media (max-width: 1335px) {
    .teaser-application {
      .teaser-application__button-container {
        min-height: 30vh;
      }

      .teaser-application__button {
        min-height: 35px !important;
        min-width: 50px !important;
        font-size: 1.55rem;
      }

      .personal-information-form__signUp-button-container {
        padding: 40px 0 100px 0;
      }

      .teaser__form-field {
        .v-input__prepend-inner {
          max-width: 22%;
        }
      }
    }
  }
  @media (max-width: 1263px) {
    .teaser-application {
      .teaser-application__button {
        font-size: 1.5rem;
      }

      .personal-information-form__signUp-button-container {
        padding: 38px 0 80px 0;
      }
    }
  }
  @media (max-width: 959px) {
    .teaser-application {
      .teaser-application__button-container {
        min-height: 20vh;
      }
      .teaser-application__button {
        font-size: 1.25rem;
      }

      .teaser-application__app-check-box.app-check-box {
        .app-check-box__icon {
          height: 25px;
          width: 25px;
        }
        .app-check-box__label {
          font-size: 14px !important;
        }
      }
      .personal-information-form__signUp-button-container {
        padding: 40px 0 100px 0;
      }
    }
  }
  @media (max-width: 599px) {
    .teaser-application {
      .teaser-application__button-container {
        min-height: 10vh;
      }
      .teaser-application__button {
        font-size: 1rem;
      }

      .teaser__form-field {
        .v-input__prepend-inner {
          max-width: 28%;
        }
      }
    }
  }
</style>
