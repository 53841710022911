<template>
  <div class="teaser-role-selection pt-5 pt-sm-10 pt-md-0">
    <div class="teaser-role-selection__option-wrapper">
      <v-row no-gutters justify="space-between">
        <template v-for="(i, index) in roles">
          <v-col
            :key="index"
            :cols="i.grid.cols"
            :md="i.grid.md"
            :xl="i.grid.xl"
            class="mb-12 mb-lg-0"
          >
            <div
              class="teaser-role-selection__option-category-label"
              v-html="$t(`label.${i.label}`)"
            ></div>

            <div
              class="d-sm-flex justify-xl-space-between pt-4 pt-sm-6 pt-md-8 pt-lg-10 pt-xl-12 text-h6"
            >
              <template v-for="(item, childrenIndex) in i.children">
                <AppFormField
                  :key="childrenIndex"
                  v-model="role"
                  type="radio"
                  :label="$t(`label.${item.label}`)"
                  :id="item.key.toString()"
                  :radioValue="item.key.toString()"
                  :size="$vuetify.breakpoint.xlOnly ? 'lg' : ''"
                  class="text-subtitle-1 text-xl-h6 mr-2 mr-xl-0 mb-2 mb-sm-0"
                ></AppFormField>
              </template>
            </div>
          </v-col>
        </template>
      </v-row>
    </div>

    <div
      class="teaser-application__button-container d-flex justify-center align-center"
    >
      <AppButton
        minHeight="60"
        minWidth="300"
        :disabled="!role"
        class="teaser-application__button"
        @click="nextAction"
        >{{ $t('label.next') }}</AppButton
      >
    </div>
  </div>
</template>

<script>
  import { ROLE_TYPE } from '@/constants/enums';

  export default {
    name: 'TeaserRoleSelection',
    data: () => ({
      roles: [
        {
          label: 'imATalent',
          grid: {
            cols: '12',
            md: '5',
            xl: '4'
          },
          children: [
            {
              key: ROLE_TYPE.TALENT,
              label: 'individual'
            },
            {
              key: ROLE_TYPE.AGENCY,
              label: 'talentAgency'
            }
          ]
        },
        {
          label: 'imLookingForTalents',
          grid: {
            cols: '12',
            md: '7',
            xl: '6'
          },
          children: [
            {
              key: ROLE_TYPE.FREELANCER,
              label: 'freelancer'
            },
            {
              key: ROLE_TYPE.PRODUCTION_HOUSE,
              label: 'productionOrCorporateOrAgency'
            }
          ]
        }
      ],
      role: null
    }),
    methods: {
      nextAction() {
        this.$emit('input', this.role);
      }
    }
  };
</script>

<style lang="scss">
  .teaser-role-selection {
    .teaser-role-selection__option-category-label {
      span,
      b {
        font-size: 3rem;
      }
    }
  }

  @media (max-width: 1903px) {
  }
  @media (max-width: 1599px) {
  }
  @media (max-width: 1535px) {
    .teaser-role-selection {
      .teaser-role-selection__option-category-label {
        span,
        b {
          font-size: 2.75rem;
        }
      }
    }
  }
  @media (max-width: 1439px) {
    .teaser-role-selection {
      .teaser-role-selection__option-category-label {
        span,
        b {
          font-size: 2.5rem;
        }
      }
    }
  }
  @media (max-width: 1335px) {
    .teaser-role-selection {
      .teaser-role-selection__option-category-label {
        span,
        b {
          font-size: 2.25rem;
        }
      }
    }
  }
  @media (max-width: 1263px) {
    .teaser-role-selection {
      .teaser-role-selection__option-category-label {
        span,
        b {
          font-size: 2rem;
        }
      }
    }
  }
  @media (max-width: 959px) {
  }
  @media (max-width: 599px) {
  }
</style>
