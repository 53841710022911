<template>
  <div class="teaser-role-talent-individual-form-container pt-6 pt-md-0">
    <!-- Personal Information Form Start -->
    <v-form
      v-model="personalInformationFormValid"
      ref="personalInformationForm"
      class="personal-information-form__wrapper"
    >
      <!-- Personal Info Form Fields Start -->
      <template v-for="(item, index) in personalInfoFormFields">
        <!-- Gender Input Field Start-->
        <div
          :key="`gender-${index}`"
          v-if="item.key == 'gender'"
          class="teaser__form-field"
          :class="`form-${item.key}`"
        >
          <div>{{ $t(`label.${item.label}`) }}</div>
          <div class="d-flex flex-wrap" id="personal-info-gender-radios">
            <template v-for="(radio, radioIndex) in item.options">
              <AppFormField
                :key="radioIndex"
                v-model="talentIndividualFormObj[item.key]"
                type="radio"
                :label="radio.text"
                :id="`personalGender-${radio.value}`"
                :radioValue="radio.value"
                class="text-subtitle-1 text-xl-h6"
                @input="personalInfoFormFieldInput(item.key)"
              ></AppFormField>
            </template>
          </div>
        </div>
        <!-- Gender Input Field End-->

        <AppFormField
          v-else-if="item.field != 'other'"
          :key="index"
          v-model="talentIndividualFormObj[item.key]"
          dark
          dense
          outlined
          :label="$t(`label.${item.label}`)"
          :height="40"
          :type="item.formType"
          :items="item.options"
          itemColor="background"
          :color="item.color"
          :rules="
            item.key == 'mobile'
              ? formValidation.mobileNumberRules(
                  talentIndividualFormObj.mobile,
                  talentIndividualFormObj.mobileDialCode
                )
              : item.rules
          "
          :multiple="item.multiple"
          class="teaser__form-field"
          :class="`form-${item.key}`"
          @input="personalInfoFormFieldInput(item.key)"
        >
          <template v-if="item.key == 'mobile'" v-slot:prepend-inner>
            <AppFormField
              v-model="talentIndividualFormObj.mobileDialCode"
              outlined
              dense
              :height="30"
              :items="phoneCodes"
              type="select"
              hideDetails
              itemColor="background"
              color="surface"
            >
            </AppFormField>
          </template>
        </AppFormField>
      </template>
      <!-- Personal Info Form Fields End -->

      <!-- Other Fields Start -->
      <template v-for="(item, index) in personalInfoFormFields">
        <AppFormField
          v-if="
            item.field == 'other' &&
            isMatchingParentValue(item.parent, item.showValue)
          "
          :key="`other-${index}`"
          v-model="talentIndividualFormObj[item.key]"
          dark
          dense
          :label="$t(`label.${item.label}`)"
          :height="40"
          :type="item.formType"
          :items="item.options"
          itemColor="background"
          :rules="item.rules"
          color="surface"
          class="teaser__form-field"
          :class="`form-${item.key}`"
        >
        </AppFormField>
      </template>
      <!-- Other Fields End -->
    </v-form>
    <!-- Personal Information Form end -->

    <!-- Guardian's Information Form Start -->
    <div v-if="isBelow18" class="guardian-information-form__container">
      <div class="guardian-information-form__title">
        <div class="text-capitalize font-weight-medium text-h6 text-lg-h5">
          {{ $t('label.guardiansInformation') }}
        </div>
        <div class="text-caption text-lg-body-2">
          {{ $t('message.ifYouAreUnderTheAgeOf18') }}
        </div>
      </div>

      <v-form
        v-model="guardianInformationFormValid"
        ref="guardianInformationForm"
        class="guardian-information-form__wrapper"
        :class="[
          { addOn: talentIndividualFormObj.guardianRelationship == 'other' }
        ]"
      >
        <template v-for="(item, index) in guardianInfoFormFields">
          <!-- Gender Input Field Start-->
          <div
            :key="`guardianGender-${index}`"
            v-if="item.key == 'guardianGender'"
            class="teaser__form-field"
            :class="`form-${item.key}`"
          >
            <div>{{ $t(`label.${item.label}`) }}</div>
            <div class="d-flex flex-wrap">
              <template v-for="(radio, radioIndex) in item.options">
                <AppFormField
                  :key="radioIndex"
                  v-model="talentIndividualFormObj[item.key]"
                  type="radio"
                  :label="radio.text"
                  :id="`guardianGender-${radio.value}`"
                  :radioValue="radio.value"
                  class="text-subtitle-1 text-xl-h6"
                  @input="guardianInfoFormFieldInput(item.key)"
                ></AppFormField>
              </template>
            </div>
          </div>
          <!-- Gender Input Field End-->

          <AppFormField
            v-else-if="item.field != 'other'"
            :key="index"
            v-model="talentIndividualFormObj[item.key]"
            dark
            dense
            outlined
            :label="$t(`label.${item.label}`)"
            :height="40"
            :type="item.formType"
            :items="item.options"
            itemColor="background"
            color="surface"
            class="teaser__form-field"
            :class="`form-${item.key}`"
            :rules="
              item.key == 'guardianMobile'
                ? formValidation.mobileNumberRules(
                    talentIndividualFormObj.mobileDialCode
                  )
                : item.rules
            "
            :multiple="item.multiple"
            @input="guardianInfoFormFieldInput(item.key)"
          >
            <template v-if="item.key == 'guardianMobile'" v-slot:prepend-inner>
              <AppFormField
                v-model="talentIndividualFormObj.guardianMobileDialCode"
                outlined
                dense
                :height="30"
                :items="phoneCodes"
                type="select"
                hideDetails
                itemColor="background"
                color="surface"
              >
              </AppFormField>
            </template>
          </AppFormField>
        </template>

        <!-- Other Fields Start -->
        <template v-for="(item, index) in guardianInfoFormFields">
          <AppFormField
            v-if="
              item.field == 'other' &&
              isMatchingParentValue(item.parent, item.showValue)
            "
            :key="`other-${index}`"
            v-model="talentIndividualFormObj[item.key]"
            dark
            dense
            :label="$t(`label.${item.label}`)"
            :height="40"
            :type="item.formType"
            :items="item.options"
            itemColor="background"
            color="surface"
            class="teaser__form-field"
            :class="`form-${item.key}`"
            :rules="item.rules"
          >
          </AppFormField>
        </template>
        <!-- Other Fields End -->
      </v-form>
    </div>
    <!-- Guardian's Information Form End -->

    <v-divider class="divider"></v-divider>

    <div class="d-flex justify-center">
      <div>
        <template v-for="(item, index) in checkboxes">
          <div
            :key="index"
            v-if="
              item.key == 'understoodAcknowledgeNConsent' ? isBelow18 : true
            "
            class="mb-6"
          >
            <AppCheckBox
              v-model="checkboxObj[item.key]"
              :label="$t(`message.${item.label}`)"
              class="teaser-application__app-check-box"
            ></AppCheckBox>
          </div>
        </template>
      </div>
    </div>
    <div
      class="personal-information-form__signUp-button-container d-flex justify-center"
    >
      <AppButton
        :disabled="
          !personalInformationFormIsValid || !guardianInformationFormIsValid
        "
        minHeight="60"
        minWidth="300"
        class="teaser-application__button"
        @click="signUpAction"
        >{{ $t('label.signUp') }}</AppButton
      >
    </div>
  </div>
</template>

<script>
  import common from '@/mixin/common.mixin';
  import AppCheckBox from '@/components/base/AppCheckBox';
  import { uiHelper, formValidationHelper, i18nHelper } from '@/utils';
  import { listService, i18nService } from '@/services';
  import { LOCAL, SHARED } from '@/constants';
  import { REGISTER_TYPE } from '@/constants/enums';
  import {
    AUTH_CHECK_AVAILABILITY_TEASER,
    AUTH_INITIAL_CHECK_AVAILABILITY_TEASER_STATE
  } from '@/store/auth.module';

  export default {
    name: 'TeaserRoleTalentIndividualForm',
    components: {
      AppCheckBox
    },
    mixins: [common],
    props: {
      value: {
        type: Object,
        default: () => ({})
      },
      role: {
        type: String,
        default: ''
      },
      otpType: {
        type: String,
        default: ''
      },
      isResetMobile: {
        type: Boolean,
        default: false
      },
      isSignUpError: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      personalInformationFormValid: false,
      guardianInformationFormValid: false,
      talentIndividualFormObj: {
        firstName: null,
        lastName: null,
        mobileDialCode: SHARED.DEFAULT_PHONE_CODE,
        mobile: null,
        email: null,
        dob: null,
        countryId: null,
        pronouns: null,
        pronounsOther: null,
        gender: null,
        genderOther: null,
        getotpby: null,
        auth_method: null,
        guardianRelationship: null,
        guardianRelationshipOther: null,
        guardianFirstName: null,
        guardianLastName: null,
        guardianMobile: null,
        guardianEmail: null,
        guardianGender: null,
        guardianPronouns: null,
        guardianGenderOther: null,
        guardianPronounsOther: null,
        guardianMobileDialCode: SHARED.DEFAULT_PHONE_CODE,
        registerType: REGISTER_TYPE.PRE_USER
      },
      checkboxObj: {
        agreeTermsOfUseNPrivacyPolicy: false,
        understoodAcknowledgeNConsent: false
      },
      personalInfoFormFields: [],
      guardianInfoFormFields: [],
      checkboxes: [
        {
          key: 'agreeTermsOfUseNPrivacyPolicy',
          label: 'messageAgreeTermsOfUseNPrivacyPolicy'
        },
        {
          key: 'understoodAcknowledgeNConsent',
          label: 'messageUnderstoodAcknowledgeNConsent'
        }
      ],
      currentLanguage: i18nService.getActiveLanguage(),
      formValidation: formValidationHelper
    }),
    computed: {
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      },
      phoneCodes() {
        return listService.getPhoneCodeList(this.constantsData.country);
      },
      isBelow18() {
        let age = uiHelper.getAge(this.talentIndividualFormObj.dob);

        return age < 18;
      },
      personalInformationFormIsValid() {
        if (
          (this.talentIndividualFormObj.mobile ||
            this.talentIndividualFormObj.email) &&
          this.checkboxObj.agreeTermsOfUseNPrivacyPolicy
        ) {
          return this.personalInformationFormValid;
        } else {
          return false;
        }
      },
      guardianInformationFormIsValid() {
        if (this.isBelow18) {
          if (
            this.talentIndividualFormObj.guardianGender &&
            this.checkboxObj.understoodAcknowledgeNConsent
          ) {
            return this.guardianInformationFormValid;
          } else {
            return false;
          }
        } else {
          return true;
        }
      },
      checkAvailabilityTeaserComplete() {
        return this.$store.state.auth.checkAvailabilityTeaser.complete;
      }
    },
    watch: {
      isBelow18() {
        if (this.isBelow18) {
          this.$nextTick(function () {
            this.insertUnderstoodMessageHyperlink();
          });
        } else {
          this.$nextTick(function () {
            this.resetGuardianInformationObj();
          });
        }
      },
      checkAvailabilityTeaserComplete() {
        let response = this.$store.state.auth.checkAvailabilityTeaser;

        if (response.complete) {
          this.checkAvailabilityTeaserCompleteAction(response);
        }
      }
    },
    mounted() {
      this.initialFormFields();
      this.insertAgreeMessageHyperlink();
      this.initialTalentIndividualFormObj();
    },
    methods: {
      updateMobileNo() {
        console.log('update here');
      },
      checkAvailabilityTeaserCompleteAction(response) {
        if (response.code == 200) {
          this.$emit('input', this.talentIndividualFormObj);
          this.$emit('sign-up');
        } else {
          let type = 'error';
          let title = i18nHelper.getMessage('label.userAvailability');
          let description = response.message;
          let buttons = [
            {
              color: 'title',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ];
          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.initialCheckAvailabilityTeaserState();
      },
      isMatchingParentValue(parentKey, displayValue) {
        let obj = this.talentIndividualFormObj;

        if (
          ['gender', 'guardianGender', 'guardianRelationship'].includes(
            parentKey
          )
        ) {
          return obj[parentKey] == displayValue;
        } else if (['pronouns', 'guardianPronouns'].includes(parentKey)) {
          if (obj[parentKey]) {
            return obj[parentKey].includes(displayValue);
          } else {
            return false;
          }
        }
      },
      resetGuardianInformationObj() {
        let list = [
          'guardianRelationship',
          'guardianRelationshipOther',
          'guardianFirstName',
          'guardianLastName',
          'guardianMobile',
          'guardianEmail',
          'guardianGender',
          'guardianPronouns',
          'guardianGenderOther',
          'guardianPronounsOther'
        ];

        list.forEach((x) => {
          this.talentIndividualFormObj[x] = null;
        });

        this.talentIndividualFormObj.guardianMobileDialCode =
          SHARED.DEFAULT_PHONE_CODE;
      },
      guardianInfoFormFieldInput(key) {
        if (key == 'guardianRelationship') {
          if (this.talentIndividualFormObj[key] != 'other') {
            this.talentIndividualFormObj.guardianRelationshipOther = null;
          }
        }

        if (key == 'guardianGender') {
          if (this.talentIndividualFormObj[key] != 'other') {
            this.talentIndividualFormObj.guardianGenderOther = null;
          }
        }

        if (key == 'guardianPronouns') {
          if (this.talentIndividualFormObj[key] != 6) {
            this.talentIndividualFormObj.guardianPronounsOther = null;
          }
        }
      },
      personalInfoFormFieldInput(key) {
        if (key == 'gender') {
          if (this.talentIndividualFormObj[key] != 'other') {
            this.talentIndividualFormObj.genderOther = null;
          }
        }

        if (key == 'pronouns') {
          if (this.talentIndividualFormObj[key] != 6) {
            this.talentIndividualFormObj.pronounsOther = null;
          }
        }
      },
      signUpAction() {
        let obj = this.talentIndividualFormObj;
        let data = {
          auth_method: obj.getotpby,
          mobileDialCode: obj.mobileDialCode,
          mobile: obj.mobile,
          role: this.role,
          email: obj.email
        };

        if (this.personalInformationFormIsValid) {
          this.checkAvailabilityTeaser(data);
        }
      },
      insertUnderstoodMessageHyperlink() {
        let understoodMessageDiv = document.getElementById('hyperlink-a-c');

        understoodMessageDiv.setAttribute('href', '/');
        understoodMessageDiv.setAttribute('target', '_blank');
        understoodMessageDiv.classList.add('secondary--text');
      },
      insertAgreeMessageHyperlink() {
        let agreeMessageDiv = document.getElementById('hyperlink-tou-pp');

        agreeMessageDiv.setAttribute('href', '/terms-of-use');
        agreeMessageDiv.setAttribute('target', '_blank');
        agreeMessageDiv.classList.add('secondary--text');
      },
      initialFormFields() {
        this.personalInfoFormFields = [
          {
            key: 'firstName',
            label: 'firstName',
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.firstName'),
              2,
              50
            )
          },
          {
            key: 'lastName',
            label: 'lastName',
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.lastName'),
              2,
              50
            )
          },
          {
            key: 'mobile',
            label: 'mobileNo'
          },
          {
            key: 'email',
            label: 'email',
            rules: formValidationHelper.emailRules()
          },
          {
            key: 'dob',
            label: 'dateOfBirth',
            formType: 'date',
            color: 'title',
            rules: formValidationHelper.dateRules(
              0,
              SHARED.DATE_TODAY,
              i18nHelper.getMessage('label.dateOfBirth')
            )
          },
          {
            key: 'countryId',
            label: 'countryOfResidence',
            formType: 'select',
            options: listService.getCountryList(
              this.constantsData.country,
              this.currentLanguage
            ),
            rules: formValidationHelper.requiredRules(
              'label.countryOfResidence'
            ),
            multiple: true
          },
          {
            key: 'gender',
            label: 'sex',
            options: listService.getDynamicList(this.constantsData.gender)
          },
          {
            key: 'pronouns',
            label: 'pronouns',
            formType: 'select',
            options: listService.getDynamicList(this.constantsData.pronouns),
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.pronouns')
            ),
            multiple: true
          },
          {
            key: 'genderOther',
            label: 'pleaseSpecific',
            field: 'other',
            parent: 'gender',
            showValue: 'other',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'pronounsOther',
            label: 'pleaseSpecific',
            field: 'other',
            parent: 'pronouns',
            showValue: 6,
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'getotpby',
            label: 'getotpby',
            formType: 'select',
            options: [
              { text: 'Email', value: 'email' },
              { text: 'Mobile No', value: 'mobile' }
            ],
            rules: formValidationHelper.requiredRules()
          }
        ];

        this.guardianInfoFormFields = [
          {
            key: 'guardianRelationship',
            label: 'relationshipWithTalent',
            formType: 'select',
            options: listService.getDynamicList(
              this.constantsData.guardianRelationship
            ),
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'guardianRelationshipOther',
            label: 'pleaseSpecific',
            field: 'other',
            parent: 'guardianRelationship',
            showValue: 'other',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'guardianFirstName',
            label: 'firstName',
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.firstName'),
              2,
              50
            )
          },
          {
            key: 'guardianLastName',
            label: 'lastName',
            rules: formValidationHelper.requiredRules(
              i18nHelper.getMessage('label.lastName'),
              2,
              50
            )
          },
          {
            key: 'guardianMobile',
            label: 'mobileNo'
          },
          {
            key: 'guardianEmail',
            label: 'email',
            rules: formValidationHelper.emailRules()
          },
          {
            key: 'guardianGender',
            label: 'sex',
            options: listService.getDynamicList(this.constantsData.gender),
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'guardianPronouns',
            label: 'pronouns',
            formType: 'select',
            options: listService.getDynamicList(this.constantsData.pronouns),
            rules: formValidationHelper.requiredRules(),
            multiple: true
          },
          {
            key: 'guardianGenderOther',
            label: 'pleaseSpecific',
            field: 'other',
            parent: 'guardianGender',
            showValue: 'other',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'guardianPronounsOther',
            label: 'pleaseSpecific',
            field: 'other',
            parent: 'guardianPronouns',
            showValue: 6,
            rules: formValidationHelper.requiredRules()
          }
        ];
      },
      initialTalentIndividualFormObj() {
        if (this.isResetMobile || this.isSignUpError) {
          let obj = this.value;
          this.talentIndividualFormObj = this.value;

          this.$nextTick(function () {
            document
              .getElementById(`personalGender-${obj.gender}`)
              .setAttribute('checked', true);

            if (obj.guardianGender) {
              document
                .getElementById(`guardianGender-${obj.guardianGender}`)
                .setAttribute('checked', true);
            }
          });
        }
      },
      checkAvailabilityTeaser(data) {
        this.$store.dispatch(AUTH_CHECK_AVAILABILITY_TEASER, { data });
      },
      initialCheckAvailabilityTeaserState() {
        this.$store.dispatch(AUTH_INITIAL_CHECK_AVAILABILITY_TEASER_STATE);
      }
    }
  };
</script>

<style lang="scss">
  .teaser-role-talent-individual-form-container {
    .divider {
      margin: 100px 0 50px 0;
    }
    .personal-information-form__wrapper {
      width: 100%;
      display: grid;
      row-gap: 24px;
      column-gap: 30px;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(4, 1fr);

      .teaser__form-field {
        &.form-firstName {
          grid-column: 1/7;
          grid-row: 1;
        }
        &.form-lastName {
          grid-column: 7/13;
          grid-row: 1;
        }
        &.form-mobile {
          grid-column: 1/7;
          grid-row: 2;
        }
        &.form-email {
          grid-column: 7/13;
          grid-row: 2;
        }
        &.form-dob {
          grid-column: 1/7;
          grid-row: 3;
        }
        &.form-countryId {
          grid-column: 7/13;
          grid-row: 3;
        }
        &.form-getotpby {
          grid-column: 1/7;
          grid-row: 4;
        }
        &.form-gender {
          grid-column: 1/7;
          grid-row: 5;
        }
        &.form-pronouns {
          grid-column: 7/13;
          grid-row: 5;
        }
        &.form-genderOther {
          grid-column: 1/7;
          grid-row: 6;
        }
        &.form-pronounsOther {
          grid-column: 7/13;
          grid-row: 6;
        }
      }
    }

    .guardian-information-form__container {
      padding-top: 120px;
      .guardian-information-form__title {
        padding-bottom: 50px;
      }

      .guardian-information-form__wrapper {
        width: 100%;
        display: grid;
        row-gap: 24px;
        column-gap: 30px;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(4, 1fr);

        .teaser__form-field {
          &.form-guardianRelationship {
            grid-column: 1/7;
            grid-row: 1;
          }
          &.form-guardianRelationshipOther {
            grid-column: 7/13;
            grid-row: 1;
          }
          &.form-guardianFirstName {
            grid-column: 1/7;
            grid-row: 2;
          }
          &.form-guardianLastName {
            grid-column: 7/13;
            grid-row: 2;
          }
          &.form-guardianMobile {
            grid-column: 1/7;
            grid-row: 3;
          }
          &.form-guardianEmail {
            grid-column: 7/13;
            grid-row: 3;
          }
          &.form-guardianGender {
            grid-column: 1/7;
            grid-row: 4;
          }
          &.form-guardianPronouns {
            grid-column: 7/13;
            grid-row: 4;
          }
          &.form-guardianGenderOther {
            grid-column: 1/7;
            grid-row: 5;
          }
          &.form-guardianPronounsOther {
            grid-column: 7/13;
            grid-row: 5;
          }
        }
      }
    }
  }

  @media (max-width: 959px) {
    .teaser-role-talent-individual-form-container {
      .divider {
        margin: 80px 0 50px 0;
      }
      .personal-information-form__wrapper {
        row-gap: 12px;
        column-gap: 0;

        .teaser__form-field {
          &.form-firstName {
            grid-column: 1/13;
            grid-row: 1;
          }
          &.form-lastName {
            grid-column: 1/13;
            grid-row: 2;
          }
          &.form-mobile {
            grid-column: 1/13;
            grid-row: 3;
          }
          &.form-email {
            grid-column: 1/13;
            grid-row: 4;
          }
          &.form-dob {
            grid-column: 1/13;
            grid-row: 5;
          }
          &.form-countryId {
            grid-column: 1/13;
            grid-row: 6;
          }
          &.form-getotpby {
            grid-column: 1/13;
            grid-row: 7;
          }
          &.form-gender {
            grid-column: 1/13;
            grid-row: 8;
          }
          &.form-pronouns {
            grid-column: 1/13;
            grid-row: 10;
          }
          &.form-genderOther {
            grid-column: 1/13;
            grid-row: 9;
          }
          &.form-pronounsOther {
            grid-column: 1/13;
            grid-row: 11;
          }
        }
      }

      .guardian-information-form__container {
        padding-top: 80px;
        .guardian-information-form__title {
          padding-bottom: 50px;
        }

        .guardian-information-form__wrapper {
          row-gap: 12px;
          column-gap: 0;

          .teaser__form-field {
            &.form-guardianRelationship {
              grid-column: 1/13;
              grid-row: 1;
            }
            &.form-guardianFirstName {
              grid-column: 1/13;
              grid-row: 2;
            }
            &.form-guardianLastName {
              grid-column: 1/13;
              grid-row: 3;
            }
            &.form-guardianMobile {
              grid-column: 1/13;
              grid-row: 4;
            }
            &.form-guardianEmail {
              grid-column: 1/13;
              grid-row: 5;
            }
            &.form-guardianGender {
              grid-column: 1/13;
              grid-row: 6;
            }
            &.form-guardianPronouns {
              grid-column: 1/13;
              grid-row: 8;
            }
            &.form-guardianGenderOther {
              grid-column: 1/13;
              grid-row: 7;
            }
            &.form-guardianPronounsOther {
              grid-column: 1/13;
              grid-row: 9;
            }
          }

          &.addOn {
            .teaser__form-field {
              &.form-guardianRelationship {
                grid-row: 1;
              }
              &.form-guardianRelationshipOther {
                grid-column: 1/13;
                grid-row: 2;
              }
              &.form-guardianFirstName {
                grid-row: 3;
              }
              &.form-guardianLastName {
                grid-row: 4;
              }
              &.form-guardianMobile {
                grid-row: 5;
              }
              &.form-guardianEmail {
                grid-row: 6;
              }
              &.form-guardianGender {
                grid-row: 7;
              }
              &.form-guardianPronouns {
                grid-row: 9;
              }
              &.form-guardianGenderOther {
                grid-row: 8;
              }
              &.form-guardianPronounsOther {
                grid-row: 10;
              }
            }
          }
        }
      }
    }
  }
</style>
