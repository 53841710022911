<template>
  <div class="teaser-otp">
    <div
      class="teaser-otp__resend-code-timer-block secondary--text font-weight-light"
    >
      {{ $t('label.resendCodeIn') }} <span id="timer"></span>
    </div>

    <v-row no-gutters>
      <v-col cols="12" lg="9" xl="7">
        <form class="teaser-otp__otp-wrapper">
          <template v-for="(item, index) in activationKeyFields">
            <input
              v-model="item.value"
              :key="index"
              :data-length="item.length"
              :data-index="index"
              maxlength="1"
              :ref="`input-${index}`"
              :disabled="!isAllowInputOTPCode"
              class="teaser-otp__otp-input d-flex justify-center align-center"
              :class="`teaser-otp__otp-item-${index}`"
              pattern="[0-9]{1}"
              type="tel"
              required
              @input="handleActivationInput($event)"
              @keydown.delete="handleDeleteInput($event)"
              @keypress="validateKeyPressNumber"
            />
          </template>
        </form>
      </v-col>
    </v-row>

    <div class="d-flex justify-center mb-6 mb-xl-10">
      <AppButton
        :disabled="isResendDisabled"
        class="teaser-application__button"
        @click="resendOTPAction"
        >{{ $t('label.resendOTP') }}</AppButton
      >
    </div>

    <div
      class="teaser-otp__change-mobile-no-btn-container d-flex justify-center"
      v-if="value.auth_method == 'mobile'"
    >
      <v-btn
        text
        class="surface--text text-decoration-underline text-capitalize font-weight-light text-subtitle-2 text-sm-subtitle-1 text-lg-h6 text-xl-h5"
        @click="changeMobileNoAction"
      >
        {{ $t('label.changeMobileNo') }}
      </v-btn>
    </div>
    <div
      class="teaser-otp__change-mobile-no-btn-container d-flex justify-center"
      v-if="value.auth_method == 'email'"
    >
      <v-btn
        text
        class="surface--text text-decoration-underline text-capitalize font-weight-light text-subtitle-2 text-sm-subtitle-1 text-lg-h6 text-xl-h5"
        @click="changeMobileNoAction"
      >
        {{ $t('label.changeEmail') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
  import common from '@/mixin/common.mixin';
  import { i18nHelper } from '@/utils';
  import {
    AUTH_REQUEST_OTP,
    AUTH_VERIFY_OTP,
    AUTH_INITIAL_REQUEST_OTP_STATE,
    AUTH_INITIAL_VERIFY_OTP_STATE
  } from '@/store/auth.module';

  export default {
    name: 'TeaserOTP',
    mixins: [common],
    props: {
      value: {
        type: Object,
        default: () => ({})
      }
    },
    data: () => ({
      activationKeyFields: [
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' }
      ],
      isAllowInputOTPCode: true,
      isResendDisabled: true,
      messageType: null
    }),
    computed: {
      activationKey() {
        let value = '';
        for (let field of this.activationKeyFields) {
          value += field.value;
        }
        return value;
      },
      requestOTPComplete() {
        return this.$store.state.auth.requestOTP.complete;
      },
      verifyOTPComplete() {
        return this.$store.state.auth.verifyOTP.complete;
      },
      isCompletedInputOTPCode() {
        let isCompleted = true;
        let numbers = [];

        for (let i = 0; i < 10; i++) {
          numbers.push(String(i));
        }

        this.activationKeyFields.forEach((x) => {
          if (!x.value) {
            isCompleted = false;
          }

          if (numbers.findIndex((y) => y == x.value) == -1) {
            isCompleted = false;
          }
        });

        return isCompleted;
      }
    },
    watch: {
      requestOTPComplete() {
        let response = this.$store.state.auth.requestOTP;

        if (response.complete) {
          this.requestOTPCompleteAction(response);
        }
      },
      verifyOTPComplete() {
        let response = this.$store.state.auth.verifyOTP;

        if (response.complete) {
          this.verifyOTPCompleteAction(response);
        }
      },
      isCompletedInputOTPCode() {
        if (this.isCompletedInputOTPCode) {
          this.verifyOTPAction();
        }
      }
    },
    created() {
      this.initTeaserOTP();
    },
    methods: {
      verifyOTPCompleteAction(response) {
        if (response.code == 200) {
          this.$emit('verify-otp-success');
        } else {
          let type = 'error';
          let title = i18nHelper.getMessage('label.verifyOTP');
          let description = response.message;
          let buttons = [
            {
              color: 'title',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ];
          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.initialVerifyOTP();
      },
      requestOTPCompleteAction(response) {
        if (response.code == 200) {
          this.$nextTick(function () {
            this.setResendOTPTimer();
            this.isAllowInputOTPCode = true;
            this.isResendDisabled = true;
          });
        } else {
          let type = 'error';
          let title = i18nHelper.getMessage('label.requestOTP');
          let description = response.message;
          let buttons = [
            {
              color: 'title',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ];
          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.initialRequestOTP();
      },
      validateKeyPressNumber(event) {
        let keyCode = event.keyCode;
        if (keyCode < 48 || keyCode > 57) {
          event.preventDefault();
        }
      },
      verifyOTPAction() {
        let value = this.value;
        let otpValue = this.activationKeyFields.map((x) => {
          return x.value;
        });
        let data = {
          mobileDialCode: value.mobileDialCode,
          mobile: value.mobile,
          auth_method: value.auth_method,
          email: value.email,
          otpType: value.otpType,
          code: `${otpValue[0]}${otpValue[1]}${otpValue[2]}${otpValue[3]}${otpValue[4]}${otpValue[5]}`
        };

        this.verifyOTP(data);
      },
      resendOTPAction() {
        this.requestOTP(this.value);
      },
      setResendOTPTimer() {
        let vm = this;
        document.getElementById('timer').innerHTML = 1 + ':' + 0;

        startTimer();

        function startTimer() {
          var presentTime = document.getElementById('timer').innerHTML;
          var timeArray = presentTime.split(/[:]+/);
          var m = timeArray[0];
          var s = checkSecond(timeArray[1] - 1);
          if (s == 59) {
            m = m - 1;
          }
          if (m < 0) {
            return;
          }

          document.getElementById('timer').innerHTML = m + ':' + s;

          if (m == '0' && s == '00') {
            vm.isResendDisabled = false;
          }

          setTimeout(startTimer, 1000);
        }

        function checkSecond(sec) {
          if (sec < 10 && sec >= 0) {
            sec = '0' + sec;
          } // add zero in front of numbers < 10
          if (sec < 0) {
            sec = '59';
          }
          return sec;
        }
      },
      changeMobileNoAction() {
        this.$emit('change-mobile-number');
      },
      handleDeleteInput(e) {
        // Grab input's value
        let value = e.target.value;
        // Grab data-index value
        let index = parseInt(e.target.dataset.index);
        // Grab data-length value

        if (['', null, undefined].includes(value)) {
          this.$refs[
            `input-${index - 1 == -1 ? 0 : parseInt(index - 1)}`
          ][0].focus();
          e.preventDefault();
        }
      },
      handleActivationInput(e) {
        // Grab input's value
        let value = e.target.value;
        // Grab data-index value
        let index = parseInt(e.target.dataset.index);
        // Grab data-length value
        let maxLength = e.target.dataset.length;

        // Shift focus to next input field if max length reached
        if (value.length >= maxLength) {
          if (typeof this.activationKeyFields[index + 1] == 'undefined') {
            e.preventDefault();
            return;
          }
          this.$refs[`input-${parseInt(index + 1)}`][0].focus();
          e.preventDefault();
        }
      },
      requestOTP(data) {
        this.$store.dispatch(AUTH_REQUEST_OTP, { data });
      },
      initialRequestOTP() {
        this.$store.dispatch(AUTH_INITIAL_REQUEST_OTP_STATE);
      },
      verifyOTP(data) {
        this.$store.dispatch(AUTH_VERIFY_OTP, { data });
      },
      initialVerifyOTP() {
        this.$store.dispatch(AUTH_INITIAL_VERIFY_OTP_STATE);
      },
      initTeaserOTP() {
        this.messageType = this.value.auth_method;
        this.requestOTP(this.value);
      }
    }
  };
</script>

<style lang="scss">
  .teaser-otp__resend-code-timer-block {
    font-size: 1.875rem;
    padding-top: 80px;
  }

  .teaser-otp__otp-wrapper {
    display: grid;
    column-gap: 30px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(1, 1fr);
    padding: 80px 0 120px 0;

    .teaser-otp__otp-input {
      border-radius: 3px;
      height: 7.5rem;
      width: 100%;
      font-size: 2.5rem;
      color: var(--v-surface-base);
      text-align: center;
      &:focus-visible {
        outline: unset !important;
      }
      &:valid {
        border: 1px solid var(--v-secondary-base);
      }
      &:invalid {
        border: 1px solid var(--v-title2-base) !important;
      }
    }
    .teaser-otp__otp-item-0 {
      grid-column: 1/2;
      grid-row: 1;
    }
    .teaser-otp__otp-item-1 {
      grid-column: 2/3;
      grid-row: 1;
    }
    .teaser-otp__otp-item-2 {
      grid-column: 3/4;
      grid-row: 1;
    }
    .teaser-otp__otp-item-3 {
      grid-column: 4/5;
      grid-row: 1;
    }
    .teaser-otp__otp-item-4 {
      grid-column: 5/6;
      grid-row: 1;
    }
    .teaser-otp__otp-item-5 {
      grid-column: 6/7;
      grid-row: 1;
    }
  }

  .teaser-otp__change-mobile-no-btn-container {
    padding-bottom: 200px;
  }

  @media (max-width: 1903px) {
    .teaser-otp__resend-code-timer-block {
      padding-top: 40px;
      font-size: 1.775rem;
    }
    .teaser-otp__otp-wrapper {
      padding: 40px 0 80px 0;
    }
  }
  @media (max-width: 1599px) {
    .teaser-otp__resend-code-timer-block {
      padding-top: 30px;
      font-size: 1.675rem;
    }
    .teaser-otp__otp-wrapper {
      padding: 30px 0 70px 0;
    }
  }
  @media (max-width: 1535px) {
    .teaser-otp__resend-code-timer-block {
      padding-top: 30px;
      font-size: 1.575rem;
    }
  }
  @media (max-width: 1439px) {
    .teaser-otp__resend-code-timer-block {
      padding-top: 20px;
      font-size: 1.475rem;
    }
    .teaser-otp__otp-wrapper {
      padding: 20px 0 50px 0;
    }
  }
  @media (max-width: 1335px) {
    .teaser-otp__resend-code-timer-block {
      padding-top: 10px;
      font-size: 1.375rem;
    }
    .teaser-otp__otp-wrapper {
      padding: 10px 0 40px 0;
    }
  }
  @media (max-width: 1263px) {
    .teaser-otp__resend-code-timer-block {
      font-size: 1.25rem;
    }
    .teaser-otp__otp-wrapper {
      column-gap: 28px;
    }
  }

  @media (max-width: 959px) {
    .teaser-otp__resend-code-timer-block {
      font-size: 1rem;
    }

    .teaser-otp__otp-wrapper {
      column-gap: 12px;
      .teaser-otp__otp-input {
        font-size: 2rem;
        height: 5.5rem;
      }
    }
    .teaser-otp__change-mobile-no-btn-container {
      padding-bottom: 100px;
    }
  }
  @media (max-width: 599px) {
    .teaser-otp__otp-wrapper {
      column-gap: 10px;
      .teaser-otp__otp-input {
        height: 3.5rem;
      }
    }
  }
</style>
