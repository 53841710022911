<template>
  <div class="teaser-page-layout content-wrapper-small">
    <div
      class="teaser-page-layout__title primary--text font-weight-bold text-uppercase"
    >
      {{ title }}
    </div>
    <div
      v-if="messageSlot"
      class="teaser-page-layout__message_container font-weight-light"
    >
      <slot name="message"></slot>
    </div>

    <slot />
  </div>
</template>

<script>
  export default {
    name: 'TeaserPageLayout',
    props: {
      title: {
        type: String,
        default: '',
        required: true
      },
      messageSlot: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style lang="scss">
  .teaser-page-layout {
    min-height: calc(100vh - 100px);
    padding-top: 120px;
    .teaser-page-layout__title {
      font-size: 6.25rem;
    }
    .teaser-page-layout__message_container {
      font-size: 1.875rem;
      min-height: 88px;
    }
  }

  @media (max-width: 1903px) {
    .teaser-page-layout {
      padding-top: 70px;
      .teaser-page-layout__title {
        font-size: 5.75rem;
      }
      .teaser-page-layout__message_container {
        font-size: 1.775rem;
      }
    }
  }
  @media (max-width: 1599px) {
    .teaser-page-layout {
      padding-top: 60px;
      .teaser-page-layout__title {
        font-size: 5.25rem;
      }
      .teaser-page-layout__message_container {
        font-size: 1.675rem;
      }
    }
  }
  @media (max-width: 1535px) {
    .teaser-page-layout {
      padding-top: 60px;
      .teaser-page-layout__title {
        font-size: 4.75rem;
      }
      .teaser-page-layout__message_container {
        font-size: 1.575rem;
      }
    }
  }
  @media (max-width: 1439px) {
    .teaser-page-layout {
      padding-top: 50px;
      .teaser-page-layout__title {
        font-size: 4.25rem;
      }
      .teaser-page-layout__message_container {
        font-size: 1.475rem;
      }
    }
  }
  @media (max-width: 1335px) {
    .teaser-page-layout {
      padding-top: 40px;
      .teaser-page-layout__title {
        font-size: 4rem;
      }
      .teaser-page-layout__message_container {
        font-size: 1.375rem;
      }
    }
  }
  @media (max-width: 1263px) {
    .teaser-page-layout {
      .teaser-page-layout__title {
        font-size: 3.75rem;
      }
      .teaser-page-layout__message_container {
        font-size: 1.25rem;
      }
    }
  }
  @media (max-width: 959px) {
    .teaser-page-layout {
      .teaser-page-layout__title {
        font-size: 3rem;
      }
      .teaser-page-layout__message_container {
        font-size: 1rem;
      }
    }
  }
</style>
