import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"teaser-role-talent-seeker-freelancer-form-container pt-6 pt-md-0"},[_c(VForm,{ref:"personalInformationForm",staticClass:"personal-information-form__wrapper",model:{value:(_vm.personalInformationFormValid),callback:function ($$v) {_vm.personalInformationFormValid=$$v},expression:"personalInformationFormValid"}},[_vm._l((_vm.personalInfoFormFields),function(item,index){return [(item.key == 'gender')?_c('div',{key:("gender-" + index),staticClass:"teaser__form-field",class:("form-" + (item.key))},[_c('div',[_vm._v(_vm._s(_vm.$t(("label." + (item.label)))))]),_c('div',{staticClass:"d-flex flex-wrap",attrs:{"id":"personal-info-gender-radios"}},[_vm._l((item.options),function(radio,radioIndex){return [_c('AppFormField',{key:radioIndex,staticClass:"text-subtitle-1 text-xl-h6",attrs:{"type":"radio","label":radio.text,"id":("personalGender-" + (radio.value)),"radioValue":radio.value},on:{"input":function($event){return _vm.personalInfoFormFieldInput(item.key)}},model:{value:(_vm.talentSeekerFreelancerFormObj[item.key]),callback:function ($$v) {_vm.$set(_vm.talentSeekerFreelancerFormObj, item.key, $$v)},expression:"talentSeekerFreelancerFormObj[item.key]"}})]})],2)]):(item.field != 'other')?_c('AppFormField',{key:index,staticClass:"teaser__form-field",class:("form-" + (item.key)),attrs:{"dark":"","dense":"","outlined":"","label":_vm.$t(("label." + (item.label))),"height":40,"type":item.formType,"items":item.options,"itemColor":"background","color":item.color,"rules":item.key == 'mobile'
            ? _vm.formValidation.mobileNumberRules(
                _vm.talentSeekerFreelancerFormObj.mobileDialCode
              )
            : item.rules,"multiple":item.multiple},on:{"input":function($event){return _vm.personalInfoFormFieldInput(item.key)}},scopedSlots:_vm._u([(item.key == 'mobile')?{key:"prepend-inner",fn:function(){return [_c('AppFormField',{staticClass:"prepend-inner foo",attrs:{"outlined":"","dense":"","height":30,"items":_vm.phoneCodes,"type":"select","hideDetails":"","itemColor":"background","color":"surface"},model:{value:(_vm.talentSeekerFreelancerFormObj.mobileDialCode),callback:function ($$v) {_vm.$set(_vm.talentSeekerFreelancerFormObj, "mobileDialCode", $$v)},expression:"talentSeekerFreelancerFormObj.mobileDialCode"}})]},proxy:true}:null],null,true),model:{value:(_vm.talentSeekerFreelancerFormObj[item.key]),callback:function ($$v) {_vm.$set(_vm.talentSeekerFreelancerFormObj, item.key, $$v)},expression:"talentSeekerFreelancerFormObj[item.key]"}}):_vm._e()]}),_vm._l((_vm.personalInfoFormFields),function(item,index){return [(
          item.field == 'other' &&
          _vm.isMatchingParentValue(item.parent, item.showValue)
        )?_c('AppFormField',{key:("other-" + index),staticClass:"teaser__form-field",class:("form-" + (item.key)),attrs:{"dark":"","dense":"","label":_vm.$t(("label." + (item.label))),"height":40,"type":item.formType,"items":item.options,"itemColor":"background","rules":item.rules,"color":"surface"},model:{value:(_vm.talentSeekerFreelancerFormObj[item.key]),callback:function ($$v) {_vm.$set(_vm.talentSeekerFreelancerFormObj, item.key, $$v)},expression:"talentSeekerFreelancerFormObj[item.key]"}}):_vm._e()]})],2),_c(VDivider,{staticClass:"divider"}),_c('div',{staticClass:"d-flex justify-center"},[_c('div',[_vm._l((_vm.checkboxes),function(item,index){return [_c('div',{key:index,staticClass:"mb-6"},[_c('AppCheckBox',{staticClass:"teaser-application__app-check-box",attrs:{"label":_vm.$t(("message." + (item.label)))},model:{value:(_vm.checkboxObj[item.key]),callback:function ($$v) {_vm.$set(_vm.checkboxObj, item.key, $$v)},expression:"checkboxObj[item.key]"}})],1)]})],2)]),_c('div',{staticClass:"personal-information-form__signUp-button-container d-flex justify-center"},[_c('AppButton',{staticClass:"teaser-application__button",attrs:{"disabled":!_vm.personalInformationFormIsValid,"minHeight":"60","minWidth":"300"},on:{"click":_vm.signUpAction}},[_vm._v(_vm._s(_vm.$t('label.signUp')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }